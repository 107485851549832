import React from 'react';
export default function Brackets({ svgClass }) {
  return (
    <svg
      className={svgClass}
      viewBox="0 0 752 752"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m171.7 382.98c-1.8477-1.8477-2.8906-4.3594-2.8906-6.9766 0-2.6133 1.043-5.125 2.8906-6.9727l78.93-78.93c3.8711-3.7422 10.027-3.6875 13.832 0.11719 3.8047 3.8086 3.8594 9.9609 0.12109 13.832l-71.957 71.953 71.957 71.957c1.9023 1.8359 2.9883 4.3633 3.0117 7.0078 0.023438 2.6484-1.0195 5.1914-2.8906 7.0625s-4.4141 2.9141-7.0625 2.8906c-2.6445-0.023438-5.1719-1.1094-7.0117-3.0117zm329.67-92.883v0.003906c-3.8711-3.7422-10.023-3.6875-13.832 0.11719-3.8047 3.8086-3.8555 9.9609-0.11719 13.832l71.953 71.953-71.953 71.953v0.003906c-1.9023 1.8359-2.9883 4.3633-3.0117 7.0078-0.023437 2.6484 1.0195 5.1914 2.8906 7.0625 1.8711 1.8711 4.4141 2.9141 7.0625 2.8906 2.6445-0.023438 5.1719-1.1094 7.0078-3.0117l78.93-78.93c1.8516-1.8516 2.8906-4.3594 2.8906-6.9766s-1.0391-5.125-2.8906-6.9727zm-95.473-74.344-78.93 315.72v0.003906c-1.3203 5.2852 1.8945 10.637 7.1797 11.957 5.2812 1.3203 10.637-1.8945 11.957-7.1797l78.93-315.72c1.3203-5.2852-1.8945-10.637-7.1797-11.957-5.2852-1.3203-10.637 1.8945-11.957 7.1758z" />
    </svg>
  );
}
