import React from 'react';
export default function LogoText({ svgClass }) {
  return (
    <svg
      className={svgClass}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 541 199"
    >
      <g>
        <path d="M268.71,174V163q-11.59,14.2-29.6,14.2-16,0-25-9.9t-9-27.7V68.39h42.8v64q0,9.81,8,9.8,6.81,0,9.8-4.6a13.07,13.07,0,0,0,1.8-7.2v-62h42.8V174Z" />
        <path d="M386.71,101.59q-1.6-9.2-10.6-9.2-8.6,0-8.6,6.38a5.46,5.46,0,0,0,2.1,4.46c1.4,1.13,3.83,2.06,7.3,2.76l10.8,2.2q16,3.41,24.6,7.7t12.2,10.6q3.6,6.3,3.6,16.1,0,17-12.6,25.81t-37,8.79q-50.4,0-53.2-37.2h40.4a9.23,9.23,0,0,0,3.7,6.2c1.93,1.34,4.77,2,8.5,2,3.33,0,5.77-.46,7.3-1.4a5,5,0,0,0,2.3-4.6,5.24,5.24,0,0,0-2.1-4.4,15.63,15.63,0,0,0-6.5-2.4l-10.6-2q-21.8-4-31.3-11.9t-9.5-22.3q0-16.2,12.8-25.1t36.4-8.9q44.21,0,50,33Z" />
        <path d="M522.91,171.19a88,88,0,0,1-28,4.6q-21.81,0-31.2-10t-9.4-33v-31h-17.2V68.39h17.2V41h42.8v27.4h21.8v33.4h-21.8v28.6c0,3.74.9,6.5,2.7,8.3s4.56,2.7,8.3,2.7a41.21,41.21,0,0,0,9.6-1.2Z" />
      </g>
      <g>
        <path d="M38.66,58.47q-6.85,17.91-6.85,40.89T38.7,140a78,78,0,0,0,13.55,22.67V35.16A81.89,81.89,0,0,0,38.66,58.47Z" />
        <path d="M16.73,73.48A71.67,71.67,0,0,0,12.42,99.2a70,70,0,0,0,4.33,25.56A49.15,49.15,0,0,0,25.27,139V58.82A51.7,51.7,0,0,0,16.73,73.48Z" />
        <path d="M151.74,85.1H117.2v39.18h21.74v12.91a37.69,37.69,0,0,1-9.11,2.71,66.1,66.1,0,0,1-11.49.91A42.82,42.82,0,0,1,103,138.35,23.29,23.29,0,0,1,92.75,131,33.41,33.41,0,0,1,87,117.85a86.88,86.88,0,0,1-1.92-19.61A92.58,92.58,0,0,1,86.69,79.5a35.51,35.51,0,0,1,4.93-12.74,20.39,20.39,0,0,1,8.6-7.3A30.32,30.32,0,0,1,112.9,57a29.64,29.64,0,0,1,7.81.94,15,15,0,0,1,9.85,7.75,29.09,29.09,0,0,1,2.72,7.39L159.09,71l25.82-2.15a66.65,66.65,0,0,0-7.59-23.12,56.08,56.08,0,0,0-15-17.3A65.72,65.72,0,0,0,140.64,17.6,96.48,96.48,0,0,0,113.35,14q-19.12,0-34,5.66a68.5,68.5,0,0,0-19.19,11l1,137.72A70.44,70.44,0,0,0,80,178.4q15.4,5.43,35.44,5.43a136.37,136.37,0,0,0,19.73-1.41A127.76,127.76,0,0,0,154,178.17a112,112,0,0,0,17.29-6.85,96,96,0,0,0,15-9.22v-77Z" />
      </g>
    </svg>
  );
}
